import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSearchParams, useLocation } from "react-router-dom";

import { Alert, Button, Collapse, Form } from "react-bootstrap";

import { get } from "utils/DeApi";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import ReportActivity from "../ReportActivity/ReportActivity";
import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import { getFormattedEmission } from "utils/StringUtils";
import ActivityList from "../../ActivityList/ActivityList";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import useContents from "components/hooks/useContents";

import UserCategories from "utils/userCategories";

import { isInvestments } from "utils/emissionFactors";
import DataLayer from "utils/DataLayer";

const ScopeThree = ({
  site,
  breadcrumbs,
  siteAccess,
  siteYearsUnderReview,
  organization,
}) => {
  const subscribedPromises = useRef([]);

  const userCategory = UserCategories();

  const [searchParams] = useSearchParams();
  const location = useLocation();

  const activityCategory = searchParams.get("activityCategory");
  const activityTypeId = searchParams.get("activityCategoryId");

  const [activityTypes, setActivityTypes] = useState([]);
  const [activities, setActivities] = useState([]);
  const [scopeAllEmissions, setScopeAllEmissions] = useState();
  const [activityTypeFilter, setActivityTypeFilter] = useState();
  const [yearFilter, setYearFilter] = useState(
    location?.state?.year || undefined
  );

  const [helpContents, setHelpContents] = useState();
  const [openHelpContents, setOpenHelpContents] = useState(false);
  const [isContentError, setIsContentError] = useState(false);

  const [isExpanding, setIsExpanding] = useState(false);
  const [expandingError, setExpandingError] = useState();
  const [showingAllActivities, setShowingAllActivities] = useState(false);
  const [offset, setOffset] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const contentId = useContents();

  // Temporary hide if consumption unknown activity types.
  const hiddenActivityTypes = [
    "6262893dec289", // Upstream - Leased Assets (If consumption unknown)
    "6262893e129e5", // Downstream - Leased Assets (If consumption unknown)
    "6262893e1a763", // Franchises (If consumption unknown)
  ];

  const fetchActivities = useCallback(() => {
    setError(null);
    setIsLoading(true);
    setOpenHelpContents(false);

    const activitiesPromise = get(`sites/${site.id}/activities`, {
      params: {
        scope: [3],
        activityTypeId: activityTypeId,
        offset: 0,
        limit: 100,
        precalc: true,
      },
    });
    const activityTypesPromise = get(`activity-types`, {
      params: {
        scope: 3,
      },
    });
    const activitiesEmissions = get("total-emissions-activities", {
      params: {
        organizationId: organization?.id,
        siteId: site?.id,
        ...(activityCategory === "Investments" && !activityTypeFilter
          ? {}
          : {
              activityTypeId: activityTypeId,
            }),
        ...(yearFilter && { year: yearFilter }),
        scope: 3,
      },
    });

    Promise.all([
      activitiesPromise.promise,
      activityTypesPromise.promise,
      activitiesEmissions.promise,
    ])
      .then((responses) => {
        setActivities(responses[0].data);
        setOffset((prevState) => prevState + responses[0].data.length);
        setScopeAllEmissions(responses[2]?.totalEmissions);
        setActivityTypes(responses[1].data);
        setIsLoading(false);
        setError(null);
        if (responses[0].data.length < 100) {
          setShowingAllActivities(true);
        } else {
          setShowingAllActivities(false);
        }
      })

      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });

    subscribedPromises.current.push(activitiesPromise, activityTypesPromise);
  }, [activityTypeId, site.id]);

  const fetchYearEmissions = () => {
    setError(null);
    setIsLoading(true);

    const activitiesEmissionsPromise = get("total-emissions-activities", {
      params: {
        organizationId: organization?.id,
        siteId: site?.id,
        ...(activityCategory === "Investments" && !activityTypeFilter
          ? {}
          : {
              activityTypeId: activityTypeFilter || activityTypeId,
            }),
        ...(yearFilter && { year: yearFilter }),
        scope: 3,
      },
    });
    activitiesEmissionsPromise.promise
      .then((response) => {
        setScopeAllEmissions(response?.totalEmissions);
        setError(null);
        setIsLoading(false);
      })

      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(activitiesEmissionsPromise);
  };

  const fetchYearActivities = () => {
    setError(null);
    setIsLoading(true);

    const activitiesYearPromise = get(`sites/${site.id}/activities`, {
      params: {
        scope: [3],
        activityTypeId: activityTypeId,
        offset: 0,
        limit: 100,
        precalc: true,
        ...(yearFilter && { yearEnded: yearFilter }),
      },
    });
    activitiesYearPromise.promise
      .then((response) => {
        setActivities(response.data);
        setOffset((prevState) => prevState + response.data.length);
        setError(null);
        setIsLoading(false);
        if (response.data.length < 100) {
          setShowingAllActivities(true);
        } else {
          setShowingAllActivities(false);
        }
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(activitiesYearPromise);
  };

  useEffect(() => {
    if (yearFilter || yearFilter === "") {
      fetchYearEmissions();
      fetchYearActivities();
      setShowingAllActivities(false);
      setOffset(0);
    }
  }, [yearFilter, activityTypeId]);

  const showMoreActivities = useCallback(
    (offset) => {
      setExpandingError(null);
      setIsExpanding(true);

      const params = {
        params: {
          scope: [3],
          activityTypeId: activityTypeId,
          offset: offset,
          limit: 100,
          precalc: true,
          ...(yearFilter && { yearEnded: yearFilter }),
        },
      };

      const activitiesPromise = get(`sites/${site.id}/activities`, params);

      activitiesPromise.promise
        .then((responses) => {
          setActivities((prevState) => [...prevState, ...responses.data]);
          setOffset((prevState) => prevState + responses.data.length);
          setExpandingError(null);
          setIsExpanding(false);
          if (responses.data.length < 100) {
            setShowingAllActivities(true);
          } else {
            setShowingAllActivities(false);
          }
        })

        .catch((error) => {
          if (!error.isCanceled) {
            setExpandingError(error);
            setIsExpanding(false);
          }
        });
      subscribedPromises.current.push(activitiesPromise);
    },
    [yearFilter, organization.id]
  );

  useEffect(() => {
    setYearFilter(location?.state?.year || undefined);
    setActivityTypeFilter();
    fetchActivities();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [fetchActivities, activityTypeId]);

  useEffect(() => {
    if (activityTypeFilter || activityTypeFilter === "") {
      fetchYearEmissions();
    }
  }, [activityTypeFilter]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!activities || !activityTypes) return <span />;

  return (
    <div id={userCategory}>
      <DataLayer />
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            ...breadcrumbs,
            {
              name: `Scope 3 - ${activityCategory}`,
              link: location.pathname + location.search,
              active: true,
            },
          ]}
        />
      </div>
      <div className="d-flex float-end">
        {siteAccess && (
          <>
            <ReportActivity
              site={site}
              activityTypes={activityTypes.filter(
                (type) =>
                  type.label.includes(activityCategory) &&
                  !hiddenActivityTypes.includes(type.id)
              )}
              onActivityReported={fetchActivities}
              isInvestments={isInvestments(activityCategory)}
              scope={3}
              siteYearsUnderReview={siteYearsUnderReview}
              organization={organization}
            />{" "}
          </>
        )}
      </div>
      <h2
        id={`Scope3Emissions-${activityCategory.replace(/\s+/g, "")}-heading`}
      >
        Scope 3 Emissions - {activityCategory}
      </h2>
      {helpContents && (
        <>
          <p className="my-3">{helpContents?.description}</p>
          <Button
            variant="link"
            onClick={() => {
              setOpenHelpContents(!openHelpContents);
            }}
            className="p-0 mt-n3"
          >
            {helpContents.title}
          </Button>
        </>
      )}
      {!isContentError && (
        <Collapse in={openHelpContents}>
          <div>
            <Alert
              variant="dark"
              className="bg-light"
              dismissible
              onClose={() => setOpenHelpContents(!openHelpContents)}
            >
              <ContentDetails
                contentId={contentId}
                onContentFetched={setHelpContents}
                setIsContentError={setIsContentError}
              />
            </Alert>
          </div>
        </Collapse>
      )}
      <hr />

      {(() => {
        let filteredActivities = [
          ...activities.filter((activity) => {
            if (activityTypeFilter)
              return activity.activityType.id === activityTypeFilter;
            else return true;
          }),
        ];

        return (
          <>
            <div className="text-end">
              {(() => {
                // For Market and Location Based
                let activityTypeOptions = activityTypes.filter(
                  (type) =>
                    type.label.includes(activityCategory) &&
                    !hiddenActivityTypes.includes(type.id)
                );

                if (activityTypeOptions.length > 1)
                  return (
                    <div className="my-3 ms-2 d-inline-flex">
                      <Form.Select
                        aria-label="category-filter"
                        size="sm"
                        value={activityTypeFilter || ""}
                        onChange={(ev) =>
                          setActivityTypeFilter(ev.target.value)
                        }
                      >
                        <option key={"All"} value={""}>
                          All Activity Types
                        </option>
                        {activityTypeOptions.map((type) => (
                          <option key={type.title} value={type.id}>
                            {type.title}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  );
                else return <span />;
              })()}
              <div className="my-3 ms-2 d-inline-flex">
                <Form.Select
                  aria-label="year-filter"
                  size="sm"
                  value={yearFilter || ""}
                  onChange={(ev) => setYearFilter(ev.target.value)}
                >
                  <option key={"All"} value={""}>
                    All Years
                  </option>
                  {(() => {
                    let startDate = new Date("2010-01-01");
                    let endDate = new Date();
                    let initialYear = startDate.getFullYear();
                    let currentYear = endDate.getFullYear();
                    const years = [];
                    while (currentYear >= initialYear) {
                      const year = currentYear--;
                      years.push(year.toString());
                    }

                    return years;
                  })().map((filter) => (
                    <option key={filter} value={filter}>
                      {filter}
                    </option>
                  ))}
                </Form.Select>
              </div>{" "}
              <div className="my-3 ms-2 d-inline-flex">
                <div className="mx-1 px-3 bg-danger text-danger bg-opacity-10">
                  <span className="fs-1">
                    {getFormattedEmission(scopeAllEmissions || 0)}{" "}
                  </span>
                  <small>
                    tCO<sub>2</sub>e
                  </small>{" "}
                  Emissions
                </div>
              </div>
            </div>

            {filteredActivities.length ? (
              <>
                <ActivityList
                  activities={filteredActivities}
                  activityTypes={activityTypes}
                  onActivityUpdated={fetchActivities}
                  siteAccess={siteAccess}
                  siteYearsUnderReview={siteYearsUnderReview}
                  isException={location?.state?.year ? true : false}
                  organization={organization}
                />
                <div className="my-3">
                  {isExpanding && <Loader />}
                  {expandingError && <ErrorHandler error={expandingError} />}
                  {!showingAllActivities && (
                    <p className="text-center">
                      <Button
                        size="sm"
                        variant="primary"
                        className="px-3 my-2 bg-opacity-25"
                        onClick={() => showMoreActivities(offset)}
                        disabled={isExpanding}
                      >
                        Show More
                      </Button>
                    </p>
                  )}
                </div>
              </>
            ) : (
              <Alert variant="info">
                There is currently nothing to show here.
              </Alert>
            )}
          </>
        );
      })()}
    </div>
  );
};

ScopeThree.propTypes = {
  site: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  siteAccess: PropTypes.bool.isRequired,
  siteYearsUnderReview: PropTypes.array.isRequired,
  organization: PropTypes.object.isRequired,
};

export default ScopeThree;
