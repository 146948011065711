import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Table,
  ButtonGroup,
  Alert,
  Form,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Button,
  OverlayTrigger,
  Collapse,
  Tooltip,
} from "react-bootstrap";

import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import AbatementChart from "./AbatementChart/AbatementChart";
import AbatementProjectCreate from "./AbatementProjectCreate/AbatementProjectCreate";
import AbatementProjectDelete from "./AbatementProjectDelete/AbatementProjectDelete";
import AbatementProjectEdit from "./AbatementProjectEdit/AbatementProjectEdit";
import { get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { useParams } from "react-router-dom";
import {
  locationsMap,
  projectCombinedStatusMap,
  projectDataConfidenceMap,
  projectTypesMap,
} from "./constants";
import AbatementLibrary from "./AbatementLibrary/AbatementLibrary";
import UserCategories from "utils/userCategories";
import DataLayer from "utils/DataLayer";
import * as echarts from "echarts";
import CsvDownloader from "components/CsvDownloader/CsvDownloader";
import AbatementProjectsUpload from "./AbatementProjectsUpload/AbatementProjectsUpload";
import { TableSortArrows } from "components/Shared/TableComponents";

const Abatement = ({
  organization,
  sites,
  onAbatementUpdated,
  status = [],
  setStatus,
  group = [],
  setGroup,
  site = [],
  setSite,
  scenario = [],
  setScenario,
  location = [],
  setLocation,
  onChartRendered,
  isShow,
  isForecastingUpdate,
  setIsForecastingUpdate,
  orgScenarios,
  orgLocations,
  orgGroups,
  orgProjects,
  orgCustomFields,
  orgEmissions,
  orgSiteLocations,
}) => {
  const [projects, setProjects] = useState([]);
  const [groups, setGroups] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [scenarios, setScenarios] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState();
  const [max, setMax] = useState(0);
  const [min, setMin] = useState(0);
  const [showLabels, setShowLabels] = useState(true);
  const [showMoreFilter, setShowMoreFilter] = useState(false);

  const [tableSortState, setTableSortState] = useState({
    sortColumn: "updatedAt",
    updatedAt: true,
    sortAsc: false,
  });

  const chartRef = useRef(null);
  const tooltipRef = useRef(null);
  const subscribedPromises = useRef([]);
  const { organizationId } = useParams();

  const userCategory = UserCategories();

  const handleDownloadClick = useCallback(() => {
    const chart = echarts.getInstanceByDom(chartRef.current);

    const base64Image = chart.getDataURL({
      pixelRatio: 2,
      backgroundColor: "#fff",
      excludeComponents: ["toolbox"],
    });

    const link = document.createElement("a");
    link.href = base64Image;
    link.download = `MACC Overview - ${organization?.name}`;
    link.click();
  }, [organization]);

  const showTooltip = () => {
    tooltipRef.current.style.visibility = "visible";
    tooltipRef.current.style.opacity = 1;
  };

  const hideTooltip = () => {
    tooltipRef.current.style.visibility = "hidden";
    tooltipRef.current.style.opacity = 0;
  };

  const handleSelect = (eventKey, cb, valArr, prevVal) => {
    if (eventKey === "clear") {
      cb([]);
    } else {
      const option = valArr.find(
        (o) => o?.key == eventKey || o?.id == eventKey
      );
      const newSelectedOptions = [...prevVal];
      const index = newSelectedOptions.findIndex(
        (o) =>
          (o?.key && o?.key === option?.key) || (o?.id && o?.id === option?.id)
      );
      if (index !== -1) {
        newSelectedOptions.splice(index, 1);
      } else {
        newSelectedOptions.push(option);
      }
      cb(newSelectedOptions);
    }
  };

  const gettitle = (arr, filter) => {
    return arr.length === 0
      ? `Select ${filter}`
      : arr.map((option) => option?.value || option?.name).join(", ");
  };

  function handleOnAbatementProjectCreated(project) {
    setProjects((prevProjects) => [project, ...prevProjects]);
    onAbatementUpdated();
  }

  function handleOnAbatementProjectDeleted(project) {
    setProjects((prevProjects) =>
      prevProjects.filter(({ id }) => id !== project.id)
    );
    onAbatementUpdated();
  }

  function handleOnAbatementProjectEdited(project) {
    setProjects((prevProjects) =>
      prevProjects.map((p) => (p.id === project.id ? project : p))
    );
    onAbatementUpdated();
  }

  function getAbatementPotential() {
    if (projects.length === 0) return 0;
    return projects
      .filter((project) => {
        if (!group.length) return 1;
        return group.filter((val) => project?.group?.id === val.id).length;
      })
      .filter((project) => {
        if (!status.length) return 1;
        return status.filter(
          (val) => parseInt(project?.projectStatus) === parseInt(val.key)
        ).length;
      })
      .filter((project) => {
        if (!site.length) return 1;
        return site.filter((val) => project?.site?.id === val.id).length;
      })
      ?.filter((project) => {
        if (!scenario.length) return 1;
        return scenario.filter((val) =>
          val.projects?.some((data) => project?.id === data?.id)
        ).length;
      })
      ?.filter((project) => {
        if (!location.length) return 1;
        return location.filter((val) => project?.location === val.name).length;
      })
      .filter(
        ({ annualCarbonEmissionReduction }) =>
          !isNaN(annualCarbonEmissionReduction)
      )
      .reduce(
        (previous, { annualCarbonEmissionReduction: current }) =>
          previous + current,
        0
      );
  }

  const fetchAbatementProjects = () => {
    setError(null);
    setIsLoading(true);
    const organizationPromise = get(
      `organizations/${organizationId}/abatement-projects`
    );
    organizationPromise.promise
      .then((response) => {
        setIsLoading(false);
        setError(null);
        setProjects(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(organizationPromise);
  };

  const fetchOpportunityGroups = () => {
    setError(null);
    setIsLoading(true);
    const groupPromise = get(`organizations/${organizationId}/groups`);
    groupPromise.promise
      .then((response) => {
        setIsLoading(false);
        setError(null);
        setGroups(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(groupPromise);
  };

  const fetchCustomFields = () => {
    setError(null);
    setIsLoading(true);
    const customFieldsPromise = get(
      `organizations/${organizationId}/custom-fields`
    );
    customFieldsPromise.promise
      .then((response) => {
        setIsLoading(false);
        setError(null);
        setCustomFields(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(customFieldsPromise);
  };

  const fetchScenarios = () => {
    setError(null);
    setIsLoading(true);
    const scenariosPromise = get(`organizations/${organizationId}/scenarios`);
    scenariosPromise.promise
      .then((response) => {
        setIsLoading(false);
        setError(null);
        setScenarios(response.data);
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(scenariosPromise);
  };

  const fetchLocations = () => {
    setError(null);
    setIsLoading(true);
    const locationsPromise = get(
      `organizations/${organizationId}/site-custom-locations?in_project=true`
    );
    locationsPromise.promise
      .then((response) => {
        setIsLoading(false);
        setError(null);
        setLocations(
          response?.data?.map((locationData, i) => ({
            id: i + 1,
            name: locationData,
          }))
        );
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(locationsPromise);
  };

  useEffect(() => {
    fetchOpportunityGroups();
    fetchAbatementProjects();
    fetchCustomFields();
    fetchScenarios();
    fetchLocations();
    // setGroups(orgGroups);
    // setProjects(orgProjects);
    // setCustomFields(orgCustomFields);
    // setScenarios(orgScenarios);
    // setLocations(
    //   orgLocations?.map((locationData, i) => ({
    //     id: i + 1,
    //     name: locationData,
    //   }))
    // );
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [organizationId]);

  useEffect(() => {
    setScenario(scenarios?.filter((scenario) => scenario?.active));
  }, [projects, scenarios]);

  const filteredMACCData = projects
    ?.filter((project) => {
      if (!group.length) return true;
      return group.filter(
        (val) =>
          project?.group?.name?.toLowerCase() === val?.name?.toLowerCase()
      ).length;
    })
    ?.filter((project) => {
      if (!status.length) return true;
      return status.filter(
        (val) => parseInt(project?.projectStatus) === parseInt(val.key)
      ).length;
    })
    ?.filter((project) => {
      if (!site.length) return true;
      return site.filter((val) => project?.site?.id === val.id).length;
    })
    ?.filter((project) => {
      if (!scenario.length) return true;
      return scenario.filter((val) =>
        val.projects?.some((data) => project?.id === data?.id)
      ).length;
    })
    ?.filter((project) => {
      if (!location.length) return true;
      return location.filter((val) => project?.location === val.name).length;
    })
    ?.map((project) => ({
      Project: project?.name,
      "Abatement Opportunity Grouping": project?.group?.name,
      "Abatement Opportunity Sub-Group":
        project?.decarbActionCategory && project?.group
          ? project?.decarbActionCategory?.name
          : projectTypesMap.find(
              ({ key }) => parseInt(key) === parseInt(project?.projectType)
            )?.value,
      Location: isNaN(Number(project?.location))
        ? project?.location
        : locationsMap.find(
            ({ key }) => parseInt(key) === parseInt(project?.location)
          )?.value,
      Site: project?.site?.name,
      Scope: project?.scope,
      Status: projectCombinedStatusMap.find(
        ({ key }) => parseInt(key) === parseInt(project?.projectStatus)
      )?.value,
      Description: project?.description
        ?.replace(/,/g, "ˏ")
        ?.replace(/"/g, "''"),
      "Project Lifetime (Yrs)": project?.projectLifetime,
      "Data Confidence": projectDataConfidenceMap?.find(
        ({ key }) => parseInt(key) === parseInt(project?.dataConfidence)
      )?.value,
      "Emission Savings Start Date": project?.emissionSavingsStartDate,
      "Size of the Project (Scaling Unit)": project?.projectSize,
      "Unit of measurement": project?.unit,
      "Max Size": project?.projectMaxSize,
      "Auto Renew Project": project?.autoRenew ? "Yes" : "No",
      "Initial Investment ($)": new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(project?.initialInvestment),
      "Annual Cashflow ($)": new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(project?.annualSavings),
      "Discount Rate (%)": project?.discountRate,
      "Disposal Cost ($)": new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(project?.disposalCost),
      "Annual Emissions Reduction": new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 3,
      }).format(project?.annualCarbonEmissionReduction),
      "Payback Period (Yrs)": project?.paybackPeriod,
      "Internal Rate of Return": !isNaN(Number(project?.internalRateOfReturn))
        ? new Intl.NumberFormat("en-US", {
            maximumFractionDigits: 3,
            style: "percent",
          }).format(project?.internalRateOfReturn / 100)
        : "N/A",
      "Net Present Value": new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(project?.netPresentValue),
      "Lifetime Emissions Reduction": new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 3,
      }).format(project?.lifetimeCarbonEmissionReduction),
      "MAC ($ per tCO2e)": new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 3,
      }).format(project?.marginalAbatementCost),
      ...Object.fromEntries(
        project?.customFields?.map((data) => [data?.label, data?.value]) || []
      ),
    }));

  const allMACCData = projects?.map((project) => {
    const customFieldsWithValues = project?.customFields?.filter(
      (field) => field.value !== null
    );

    const customFields = Array.from(
      { length: 15 },
      (_, i) => customFieldsWithValues?.[i] || {}
    );

    let result = {
      "Organization Name *": organization?.name,
      "Project Name *": project?.name,
      "Abatement Opportunity Grouping (Optional)": project?.group?.name,
      "Abatement Opportunity Sub-Group (Optional)":
        project?.decarbActionCategory
          ? project?.decarbActionCategory?.name && project?.group
          : projectTypesMap.find(
              ({ key }) => parseInt(key) === parseInt(project?.projectType)
            )?.value,
      "Location *": isNaN(Number(project?.location))
        ? project?.location
        : locationsMap.find(
            ({ key }) => parseInt(key) === parseInt(project?.location)
          )?.value,
      "Site (Optional)": project?.site?.name,
      "Scope (Optional)": project?.scope,
      "Status *": projectCombinedStatusMap.find(
        ({ key }) => parseInt(key) === parseInt(project?.projectStatus)
      )?.value,
      "Description (Optional)": project?.description
        ?.replace(/,/g, "ˏ")
        ?.replace(/"/g, "''"),
      "Project Lifetime (Yrs) *": project?.projectLifetime,
      "Data Confidence (Optional)": projectDataConfidenceMap?.find(
        ({ key }) => parseInt(key) === parseInt(project?.dataConfidence)
      )?.value,
      "Emission Savings Start Date *": project?.emissionSavingsStartDate,
      "Size of the Project (Scaling Unit) (Optional)": project?.projectSize,
      "Unit of measurement (Optional)": project?.unit,
      "Max Size (Optional)": project?.projectMaxSize,
      "Auto Renew Project *": project?.autoRenew ? "Yes" : "No",
      "Initial Investment ($) *": project?.initialInvestment,
      "Annual Cashflow ($) *": project?.annualSavings,
      "Discount Rate (%) *": project?.discountRate,
      "Disposal Cost ($) *": project?.disposalCost,
      "Annual Emissions Reduction (tCO2e) *":
        project?.annualCarbonEmissionReduction,
    };

    customFields.forEach((customField, i) => {
      result[`Custom Label ${i + 1}`] = customField?.label || "";
      result[`Custom Field Type ${i + 1}`] =
        customField?.type?.toLowerCase() === "number"
          ? "Number"
          : customField?.type?.toLowerCase() === "textarea"
          ? "TextArea"
          : customField?.type?.toLowerCase() === "date"
          ? "Date"
          : "";
      result[`Custom Field Value ${i + 1}`] = customField?.value || "";
    });

    result = {
      ...result,
      "Payback Period (Yrs)": project?.paybackPeriod,
      "Internal Rate of Return": !isNaN(Number(project?.internalRateOfReturn))
        ? new Intl.NumberFormat("en-US", {
            maximumFractionDigits: 3,
            style: "percent",
          }).format(project?.internalRateOfReturn / 100)
        : "N/A",
      "Net Present Value": new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(project?.netPresentValue),
      "Lifetime Emissions Reduction": new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 3,
      }).format(project?.lifetimeCarbonEmissionReduction),
      "MAC ($ per tCO2e)": new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 3,
      }).format(project?.marginalAbatementCost),
    };
    return result;
  });

  const sortOrganizations = (column, objValue) => {
    projects.sort((a, b) => {
      let macA = objValue ? a[column]?.[objValue] : a[column];
      let macB = objValue ? b[column]?.[objValue] : b[column];

      if (macA === null || macA === undefined || macA === "n/a") {
        return tableSortState[column] ? 1 : -1;
      }

      if (macB === null || macB === undefined || macB === "n/a") {
        return tableSortState[column] ? -1 : 1;
      }
      const isDateA = /^\d{2}\/\d{2}\/\d{4}$/.test(macA);
      const isDateB = /^\d{2}\/\d{2}\/\d{4}$/.test(macB);

      if (isDateA && isDateB) {
        const [monthA, dayA, yearA] = macA.split("/");
        const [monthB, dayB, yearB] = macB.split("/");
        macA = new Date(yearA, monthA - 1, dayA);
        macB = new Date(yearB, monthB - 1, dayB);
      }

      if (typeof macA === "number" && typeof macB === "number") {
        if (!tableSortState[column]) return macB - macA;
        return macA - macB;
      } else if (isDateA && isDateB) {
        if (!tableSortState[column]) return macB - macA;
        return macA - macB;
      } else {
        if (!tableSortState[column])
          return macA.localeCompare(macB, "en-us") <= 0 ? 1 : -1;
        else return macA.localeCompare(macB, "en-us") >= 0 ? 1 : -1;
      }
    });

    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });
    setProjects([...projects]);
  };

  const sortCustomFields = (column, fieldName, fieldType) => {
    const a = projects.sort((a, b) => {
      let valueA = a?.customFields?.find(
        (data) => data?.label === fieldName && data?.type === fieldType
      );

      let valueB = b?.customFields?.find(
        (data) => data?.label === fieldName && data?.type === fieldType
      );

      if (
        valueA === undefined ||
        valueA?.value === null ||
        valueA?.value === undefined
      ) {
        return tableSortState[column] ? 1 : -1;
      }

      if (
        valueB === undefined ||
        valueB?.value === null ||
        valueB?.value === undefined
      ) {
        return tableSortState[column] ? -1 : 1;
      }

      if (tableSortState[column]) {
        return valueA.value > valueB.value ? 1 : -1;
      } else {
        return valueA.value < valueB.value ? 1 : -1;
      }
    });

    tableSortState.sortColumn = fieldName;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];
    setTableSortState({ ...tableSortState });
    setProjects([...a]);
  };

  return (
    <div id={userCategory}>
      <DataLayer />
      {isShow && (
        <div className="my-3">
          <BreadCrumbs
            breadcrumbs={[
              { name: "Dashboard", link: "/" },
              {
                name: organization.name,
                link: `/organizations/${organization.id}`,
              },
              {
                name: "Abatement Planning",
                link: `/organizations/${organization.id}/abatement`,
                active: true,
              },
            ]}
          />
        </div>
      )}
      <div className="d-inline-flex float-end">
        <div className="mx-1 px-3 bg-success text-success bg-opacity-10">
          <span className="fs-1">
            {new Intl.NumberFormat("en-US", {
              maximumFractionDigits: 3,
            }).format(getAbatementPotential())}{" "}
          </span>
          <small>
            tCO<sub>2</sub>e
          </small>{" "}
          <small>/year Abatement Potential</small>
        </div>
      </div>
      {isShow ? (
        <h2 className="mb-4 pt-2">Abatement Planning</h2>
      ) : (
        <h3 className="mb-4">Abatement Planning</h3>
      )}
      <div className={`${isShow ? "my-3 p-3 border" : ""}`}>
        {isShow && (
          <div className="d-flex align-items-center justify-content-between">
            <h3 className="m-0">
              <span>
                Marginal Abatement Cost Curve
                <br />
                <small>In {new Date().getFullYear()} Value</small>
              </span>
            </h3>
            <div>
              <AbatementLibrary
                sites={sites}
                onAbatementProjectCreated={handleOnAbatementProjectCreated}
              />
              <AbatementProjectCreate
                sites={sites}
                onAbatementProjectCreated={handleOnAbatementProjectCreated}
                organization={organization}
                orgEmissions={orgEmissions}
                orgGroups={orgGroups}
                orgCustomFields={orgCustomFields}
                orgSiteLocations={orgSiteLocations}
                isForecastingUpdate={isForecastingUpdate}
                setIsForecastingUpdate={setIsForecastingUpdate}
              />
              <AbatementProjectsUpload
                onProjectsUploaded={onAbatementUpdated}
                organization={organization}
                allMACCData={allMACCData}
              />
            </div>
          </div>
          // </h3>
        )}
        <hr />
        <Row>
          <Form.Group
            as={Col}
            xs={12}
            md={4}
            controlId="Sectors"
            className="mb-3"
          >
            <Form.Label>Filter by status</Form.Label>
            <DropdownButton
              title={gettitle(status, "status")}
              id="multi-select-dropdown"
              itemID="filterByStatus"
              onSelect={(e) =>
                handleSelect(
                  e,
                  setStatus,
                  projectCombinedStatusMap.sort((a, b) => a.order - b.order),
                  status
                )
              }
              variant="outline-dark"
              className="no-hover"
              autoClose="outside"
            >
              {projectCombinedStatusMap
                .sort((a, b) => a.order - b.order)
                .map(({ key, value }, ind) => (
                  <Dropdown.Item
                    key={key}
                    eventKey={key}
                    active={status.some((o) => o.value === value)}
                    id={`filterByStatusOption${ind + 1}`}
                  >
                    {status.some((o) => o.value === value) ? (
                      <span className="material-icons-outlined md-16 me-1">
                        check_box
                      </span>
                    ) : (
                      <span className="material-icons-outlined md-16 me-1">
                        check_box_outline_blank
                      </span>
                    )}
                    {value}
                  </Dropdown.Item>
                ))}
              <Dropdown.Divider />
              <Dropdown.Item eventKey="clear" disabled={status.length === 0}>
                Clear selections
              </Dropdown.Item>
            </DropdownButton>
          </Form.Group>
          <Form.Group
            as={Col}
            xs={12}
            md={4}
            controlId="Sectors"
            className="mb-3"
          >
            <Form.Label>Filter by group</Form.Label>
            <DropdownButton
              title={gettitle(group, "group")}
              id="multi-select-dropdown"
              itemID="filterByGroup"
              onSelect={(e) => handleSelect(e, setGroup, groups, group)}
              variant="outline-dark"
              className="no-hover"
              autoClose="outside"
            >
              {groups?.map(({ id, name }, ind) => (
                <Dropdown.Item
                  key={id}
                  eventKey={id}
                  active={group.some((o) => o.name === name)}
                  id={`filterByGroupOption${ind + 1}`}
                >
                  {group.some((o) => o.name === name) ? (
                    <span className="material-icons-outlined md-16 me-1">
                      check_box
                    </span>
                  ) : (
                    <span className="material-icons-outlined md-16 me-1">
                      check_box_outline_blank
                    </span>
                  )}
                  {name}
                </Dropdown.Item>
              ))}
              <Dropdown.Divider />
              <Dropdown.Item eventKey="clear" disabled={group.length === 0}>
                Clear selections
              </Dropdown.Item>
            </DropdownButton>
          </Form.Group>
          <Form.Group as={Col} xs={12} md={4} controlId="site" className="mb-3">
            <Form.Label>Filter by site</Form.Label>
            <DropdownButton
              title={gettitle(site, "site")}
              id="multi-select-dropdown"
              itemID="filterBySite"
              onSelect={(e) => handleSelect(e, setSite, sites, site)}
              variant="outline-dark"
              className="no-hover"
              autoClose="outside"
            >
              {sites
                ?.sort((a, b) =>
                  a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())
                )
                ?.map(({ id, name }, ind) => (
                  <Dropdown.Item
                    key={id}
                    eventKey={id}
                    active={site.some((o) => o.name === name)}
                    id={`filterBySiteOption${ind + 1}`}
                  >
                    {site.some((o) => o.name === name) ? (
                      <span className="material-icons-outlined md-16 me-1">
                        check_box
                      </span>
                    ) : (
                      <span className="material-icons-outlined md-16 me-1">
                        check_box_outline_blank
                      </span>
                    )}
                    {name}
                  </Dropdown.Item>
                ))}
              <Dropdown.Divider />
              <Dropdown.Item eventKey="clear" disabled={site.length === 0}>
                Clear selections
              </Dropdown.Item>
            </DropdownButton>
          </Form.Group>
          <div className="py-1">
            <Button
              variant="link"
              onClick={() => {
                setShowMoreFilter(!showMoreFilter);
              }}
              className="p-0 mt-n3"
            >
              Show more filters
            </Button>
            <Collapse in={showMoreFilter}>
              <div>
                <Alert
                  variant="dark"
                  className="bg-light"
                  dismissible
                  onClose={() => setShowMoreFilter(!showMoreFilter)}
                >
                  <Row>
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={6}
                      controlId="scenarios"
                      className="mb-3"
                    >
                      <Form.Label>Filter by scenario</Form.Label>
                      <DropdownButton
                        title={gettitle(scenario, "scenario")}
                        id="multi-select-dropdown"
                        itemID="filterByScenario"
                        onSelect={(e) =>
                          handleSelect(e, setScenario, scenarios, scenario)
                        }
                        variant="outline-dark"
                        className="no-hover"
                        autoClose="outside"
                      >
                        {scenarios?.map(({ id, name }, ind) => (
                          <Dropdown.Item
                            key={id}
                            eventKey={id}
                            active={scenario.some((o) => o.name === name)}
                            id={`filterByScenarioOption${ind + 1}`}
                          >
                            {scenario.some((o) => o.name === name) ? (
                              <span className="material-icons-outlined md-16 me-1">
                                check_box
                              </span>
                            ) : (
                              <span className="material-icons-outlined md-16 me-1">
                                check_box_outline_blank
                              </span>
                            )}
                            {name}
                          </Dropdown.Item>
                        ))}
                        <Dropdown.Divider />
                        <Dropdown.Item
                          eventKey="clear"
                          disabled={scenario.length === 0}
                        >
                          Clear selections
                        </Dropdown.Item>
                      </DropdownButton>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={6}
                      controlId="locations"
                      className="mb-3"
                    >
                      <Form.Label>Filter by location</Form.Label>
                      <DropdownButton
                        title={gettitle(location, "location")}
                        id="multi-select-dropdown"
                        itemID="filterByLocation"
                        onSelect={(e) =>
                          handleSelect(e, setLocation, locations, location)
                        }
                        variant="outline-dark"
                        className="no-hover"
                        autoClose="outside"
                      >
                        {locations
                          ?.sort((a, b) =>
                            a?.name
                              ?.toLowerCase()
                              .localeCompare(b?.name?.toLowerCase())
                          )
                          ?.map(({ id, name }, ind) => (
                            <Dropdown.Item
                              key={id}
                              eventKey={id}
                              active={location.some((o) => o.name === name)}
                              id={`filterByLocationOption${ind + 1}`}
                            >
                              {location.some((o) => o.name === name) ? (
                                <span className="material-icons-outlined md-16 me-1">
                                  check_box
                                </span>
                              ) : (
                                <span className="material-icons-outlined md-16 me-1">
                                  check_box_outline_blank
                                </span>
                              )}
                              {name}
                            </Dropdown.Item>
                          ))}
                        <Dropdown.Divider />
                        <Dropdown.Item
                          eventKey="clear"
                          disabled={location.length === 0}
                        >
                          Clear selections
                        </Dropdown.Item>
                      </DropdownButton>
                    </Form.Group>
                  </Row>
                </Alert>
              </div>
            </Collapse>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <Form.Group
              controlId="chartLabelsSwitch"
              className="d-flex align-items-center mb-3"
            >
              <Form.Check
                group="switch"
                id="custom-switch-line"
                className="form-check form-switch"
                checked={showLabels}
                onChange={(e) => setShowLabels(e.target.checked)}
              />
              <label
                title=""
                htmlFor="custom-switch-line"
                className="text-muted me-1 fs-5"
              >
                <small>SHOW PROJECT LABELS</small>
              </label>
              <div
                className="customOverlay ms-2 text-muted"
                onMouseOver={showTooltip}
                onFocus={showTooltip}
                onMouseOut={hideTooltip}
                onBlur={hideTooltip}
              >
                <span className="material-icons-outlined md-16 customFilterPointer me-1">
                  info
                </span>
                <div ref={tooltipRef} className="customTooltip">
                  {!!status?.length && (
                    <div>
                      <div>Filter by status</div>
                      {status?.map((data) => (
                        <li>{data?.value}</li>
                      ))}
                    </div>
                  )}
                  {!!group?.length && (
                    <div>
                      <div>Filter by group</div>
                      {group?.map((data) => (
                        <li>{data?.name}</li>
                      ))}
                    </div>
                  )}
                  {!!site?.length && (
                    <div>
                      <div>Filter by site</div>
                      {site?.map((data) => (
                        <li>{data?.name}</li>
                      ))}
                    </div>
                  )}
                  {!!scenario?.length && (
                    <div>
                      <div>Filter by scenario</div>
                      {scenario?.map((data) => (
                        <li>{data?.name}</li>
                      ))}
                    </div>
                  )}
                  {!!location?.length && (
                    <div>
                      <div>Filter by location</div>
                      {location?.map((data) => (
                        <li>{data?.name}</li>
                      ))}
                    </div>
                  )}
                  {!status?.length &&
                    !group?.length &&
                    !site?.length &&
                    !scenario?.length &&
                    !location?.length &&
                    "N/A"}
                </div>
              </div>
              <span className="text-muted">Applied Filters</span>
            </Form.Group>
            <div>
              <Button
                size="sm"
                title="Reset Filters"
                className="mb-3 downloadChart px-0"
                onClick={() => {
                  setStatus([]);
                  setGroup([]);
                  setSite([]);
                  setScenario([]);
                  setLocation([]);
                }}
              >
                <span className="material-icons-outlined md-18">
                  restart_alt
                </span>
              </Button>
              <Button
                size="sm"
                onClick={handleDownloadClick}
                title="Save as Image"
                className="mb-3 downloadChart"
              >
                <span className="material-icons-outlined md-18">
                  file_download
                </span>
              </Button>
            </div>
          </div>
        </Row>
        <div className="my-3">
          {!isLoading && !error && (
            <AbatementChart
              data={
                !min ||
                !Number.isFinite(min) ||
                Number.isNaN(min) ||
                !max ||
                !Number.isFinite(max) ||
                Number.isNaN(max)
                  ? projects
                  : projects
                      .filter((project) => {
                        if (!group.length) return true;
                        return group.filter(
                          (val) =>
                            project?.group?.name?.toLowerCase() ===
                            val?.name?.toLowerCase()
                        ).length;
                      })
                      .filter((project) => {
                        if (!status.length) return true;
                        return status.filter(
                          (val) =>
                            parseInt(project?.projectStatus) ===
                            parseInt(val.key)
                        ).length;
                      })
                      .filter((project) => {
                        if (!site.length) return true;
                        return site.filter(
                          (val) => project?.site?.id === val.id
                        ).length;
                      })
                      ?.filter((project) => {
                        if (!scenario.length) return true;
                        return scenario.filter((val) =>
                          val.projects?.some((data) => project?.id === data?.id)
                        ).length;
                      })
                      ?.filter((project) => {
                        if (!location.length) return true;
                        return location.filter(
                          (val) => project?.location === val.name
                        ).length;
                      })
                      .map((project) => project)
              }
              max={max}
              min={min}
              setMin={setMin}
              setMax={setMax}
              onChartRendered={onChartRendered}
              showLabels={showLabels}
              el={chartRef}
            />
          )}
          {error && !isLoading && <ErrorHandler error={error} />}
          {isLoading && <Loader />}
          <small className="text-primary d-block mt-2">
            *We are showing first 100 items based on the selected filter.
          </small>
        </div>
      </div>
      {isShow && (
        <div className="d-flex justify-content-between">
          <h4>Abatement Opportunities</h4>
          {!!filteredMACCData?.length && (
            <CsvDownloader
              data={filteredMACCData}
              fileName={`${organization?.name} - Project List.csv`}
              className="downloadChart"
              title="Export Data To Excel (.csv)"
            />
          )}
        </div>
      )}
      {isShow && (
        <div className="abatementTable">
          <Table
            size="sm"
            responsive
            striped
            className="first-column-fixed last-column-fixed "
          >
            <thead>
              <tr>
                <th
                  className="text-nowrap px-3 mw-50 projectCol table-sorter"
                  onClick={() => !isLoading && sortOrganizations("name")}
                >
                  Project
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"name"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-nowrap px-3 table-sorter"
                  onClick={() =>
                    !isLoading && sortOrganizations("group", "name")
                  }
                >
                  Abatement Opportunity Grouping
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"group"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-nowrap px-3 table-sorter"
                  onClick={() => !isLoading && sortOrganizations("projectType")}
                >
                  Abatement Opportunity Sub-Group
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"projectType"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-nowrap px-3 table-sorter"
                  onClick={() => !isLoading && sortOrganizations("location")}
                >
                  Location
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"location"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-nowrap px-3 table-sorter"
                  onClick={() =>
                    !isLoading && sortOrganizations("site", "name")
                  }
                >
                  Site
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"site"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-nowrap px-3 table-sorter"
                  onClick={() => !isLoading && sortOrganizations("scope")}
                >
                  Scope
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"scope"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-nowrap px-3 table-sorter"
                  onClick={() =>
                    !isLoading && sortOrganizations("projectStatus")
                  }
                >
                  Status
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"projectStatus"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-end text-nowrap px-3 table-sorter"
                  onClick={() =>
                    !isLoading && sortOrganizations("projectLifetime")
                  }
                >
                  Project Lifetime <small>(Yrs)</small>
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"projectLifetime"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-nowrap px-3 table-sorter"
                  onClick={() =>
                    !isLoading && sortOrganizations("dataConfidence")
                  }
                >
                  Data Confidence
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"dataConfidence"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-end text-nowrap px-3 table-sorter"
                  onClick={() =>
                    !isLoading && sortOrganizations("emissionSavingsStartDate")
                  }
                >
                  Emission Savings Start Date
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"emissionSavingsStartDate"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-end text-nowrap px-3 table-sorter"
                  onClick={() => !isLoading && sortOrganizations("projectSize")}
                >
                  Size of the Project (Scaling Unit)
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"projectSize"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-end text-nowrap px-3 table-sorter"
                  onClick={() => !isLoading && sortOrganizations("unit")}
                >
                  Unit of measurement
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"unit"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-end text-nowrap px-3 table-sorter"
                  onClick={() =>
                    !isLoading && sortOrganizations("projectMaxSize")
                  }
                >
                  Max Size
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"projectMaxSize"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-nowrap px-3 table-sorter"
                  onClick={() => !isLoading && sortOrganizations("autoRenew")}
                >
                  Auto Renew Project
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"autoRenew"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-end text-nowrap px-3 table-sorter"
                  onClick={() =>
                    !isLoading && sortOrganizations("initialInvestment")
                  }
                >
                  Initial Investment ($)
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"initialInvestment"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-end text-nowrap px-3 table-sorter"
                  onClick={() =>
                    !isLoading && sortOrganizations("annualSavings")
                  }
                >
                  Annual Cashflow ($)
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"annualSavings"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-end text-nowrap px-3 table-sorter"
                  onClick={() =>
                    !isLoading && sortOrganizations("discountRate")
                  }
                >
                  Discount Rate (%)
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"discountRate"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-end text-nowrap px-3 table-sorter"
                  onClick={() =>
                    !isLoading && sortOrganizations("disposalCost")
                  }
                >
                  Disposal Cost ($)
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"disposalCost"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-end text-nowrap px-3 table-sorter"
                  onClick={() =>
                    !isLoading &&
                    sortOrganizations("annualCarbonEmissionReduction")
                  }
                >
                  Annual Emissions Reduction
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"annualCarbonEmissionReduction"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-end text-nowrap px-3 table-sorter"
                  onClick={() =>
                    !isLoading && sortOrganizations("paybackPeriod")
                  }
                >
                  Payback Period <small>(Yrs)</small>
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"paybackPeriod"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-end text-nowrap px-3 table-sorter"
                  onClick={() =>
                    !isLoading && sortOrganizations("internalRateOfReturn")
                  }
                >
                  Internal Rate of Return
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"internalRateOfReturn"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-end text-nowrap px-3 table-sorter"
                  onClick={() =>
                    !isLoading && sortOrganizations("netPresentValue")
                  }
                >
                  Net Present Value
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"netPresentValue"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-end text-nowrap px-3 table-sorter"
                  onClick={() =>
                    !isLoading &&
                    sortOrganizations("lifetimeCarbonEmissionReduction")
                  }
                >
                  Lifetime Emissions Reduction
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"lifetimeCarbonEmissionReduction"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                <th
                  className="text-end text-nowrap px-3 table-sorter"
                  onClick={() =>
                    !isLoading && sortOrganizations("marginalAbatementCost")
                  }
                >
                  MAC{" "}
                  <small>
                    ($ per tCO<sub>2</sub>e)
                  </small>
                  <TableSortArrows
                    sortColumn={tableSortState.sortColumn}
                    colName={"marginalAbatementCost"}
                    sortAsc={tableSortState.sortAsc}
                  />
                </th>
                {customFields?.map((customField, index) => (
                  <th
                    className={`${
                      customField?.type !== "textarea" ? "text-end" : ""
                    } text-nowrap px-3 table-sorter`}
                    onClick={() =>
                      !isLoading &&
                      sortCustomFields(
                        "customField",
                        customField.label,
                        customField.type
                      )
                    }
                  >
                    {customField?.label}
                    <TableSortArrows
                      sortColumn={tableSortState.sortColumn}
                      colName={customField.label}
                      sortAsc={tableSortState.sortAsc}
                    />
                  </th>
                ))}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {projects
                ?.filter((project) => {
                  if (!group.length) return true;
                  return group.filter(
                    (val) =>
                      project?.group?.name?.toLowerCase() ===
                      val?.name?.toLowerCase()
                  ).length;
                })
                ?.filter((project) => {
                  if (!status.length) return true;
                  return status.filter(
                    (val) =>
                      parseInt(project?.projectStatus) === parseInt(val.key)
                  ).length;
                })
                ?.filter((project) => {
                  if (!site.length) return true;
                  return site.filter((val) => project?.site?.id === val.id)
                    .length;
                })
                ?.filter((project) => {
                  if (!scenario.length) return true;
                  return scenario.filter((val) =>
                    val.projects?.some((data) => project?.id === data?.id)
                  ).length;
                })
                ?.filter((project) => {
                  if (!location.length) return true;
                  return location.filter(
                    (val) => project?.location === val.name
                  ).length;
                })
                ?.map((project) => (
                  <tr key={project?.id}>
                    <td
                      className={`${
                        project?.name?.length > 30 ? "text-wrap" : "text-nowrap"
                      } px-3 projectCol mw-50 fixedTd`}
                    >
                      {project?.name}{" "}
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip>
                            {project?.description || "No description added."}
                          </Tooltip>
                        }
                      >
                        <span className="material-icons-outlined md-18">
                          info
                        </span>
                      </OverlayTrigger>
                    </td>
                    <td className="text-nowrap px-3 align-middle">
                      {project?.group?.name}
                    </td>
                    <td className="text-nowrap px-3 align-middle">
                      {project?.decarbActionCategory && project?.group
                        ? project?.decarbActionCategory?.name
                        : projectTypesMap.find(
                            ({ key }) =>
                              parseInt(key) === parseInt(project?.projectType)
                          )?.value}
                    </td>

                    <td className="text-nowrap px-3 align-middle">
                      {isNaN(Number(project?.location))
                        ? project?.location
                        : locationsMap.find(
                            ({ key }) =>
                              parseInt(key) === parseInt(project?.location)
                          )?.value}
                    </td>
                    <td className="text-nowrap px-3 align-middle">
                      {project?.site?.name}
                    </td>
                    <td className="text-end text-nowrap px-3 align-middle">
                      {project?.scope}
                    </td>
                    <td className="text-nowrap px-3 align-middle">
                      {
                        projectCombinedStatusMap.find(
                          ({ key }) =>
                            parseInt(key) === parseInt(project?.projectStatus)
                        )?.value
                      }
                    </td>
                    <td className="text-end text-nowrap px-3 align-middle">
                      {project?.projectLifetime}
                    </td>
                    <td className="text-nowrap px-3 align-middle">
                      {
                        projectDataConfidenceMap?.find(
                          ({ key }) =>
                            parseInt(key) === parseInt(project?.dataConfidence)
                        )?.value
                      }
                    </td>
                    <td className="text-end text-nowrap px-3 align-middle">
                      {project?.emissionSavingsStartDate}
                    </td>
                    <td className="text-end text-nowrap px-3 align-middle">
                      {project?.projectSize}
                    </td>
                    <td className="text-end text-nowrap px-3 align-middle">
                      {project?.unit}
                    </td>
                    <td className="text-end text-nowrap px-3 align-middle">
                      {project?.projectMaxSize}
                    </td>
                    <td className="text-nowrap px-3 align-middle">
                      {project?.autoRenew ? "Yes" : "No"}
                    </td>
                    <td className="text-end text-nowrap px-3 align-middle">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(project?.initialInvestment)}
                    </td>
                    <td className="text-end text-nowrap px-3 align-middle">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(project?.annualSavings)}
                    </td>
                    <td className="text-end text-nowrap px-3 align-middle">
                      {project?.discountRate}
                    </td>
                    <td className="text-end text-nowrap px-3 align-middle">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(project?.disposalCost)}
                    </td>
                    <td className="text-end text-nowrap px-3 align-middle">
                      {new Intl.NumberFormat("en-US", {
                        maximumFractionDigits: 3,
                      }).format(project?.annualCarbonEmissionReduction)}
                    </td>
                    <td className="text-end text-nowrap px-3 align-middle">
                      {project?.paybackPeriod}
                    </td>
                    <td className="text-end text-nowrap px-3 align-middle">
                      {!isNaN(Number(project?.internalRateOfReturn))
                        ? new Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 3,
                            style: "percent",
                          }).format(project?.internalRateOfReturn / 100)
                        : "N/A"}
                    </td>
                    <td className="text-end text-nowrap px-3 align-middle">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(project?.netPresentValue)}
                    </td>
                    <td className="text-end text-nowrap px-3 align-middle">
                      {new Intl.NumberFormat("en-US", {
                        maximumFractionDigits: 3,
                      }).format(project?.lifetimeCarbonEmissionReduction)}
                    </td>
                    <td className="text-end text-nowrap px-3 align-middle">
                      {new Intl.NumberFormat("en-US", {
                        maximumFractionDigits: 3,
                      }).format(project?.marginalAbatementCost)}
                    </td>
                    {customFields?.map((customField) => (
                      <td
                        className={`${
                          customField?.type !== "textarea" ? "text-end" : ""
                        } text-nowrap px-3 align-middle`}
                      >
                        {
                          project?.customFields?.find(
                            (data) =>
                              data?.label?.toLowerCase() ===
                                customField?.label?.toLowerCase() &&
                              data?.type?.toLowerCase() ===
                                customField?.type?.toLowerCase()
                          )?.value
                        }
                      </td>
                    ))}
                    <td className="text-end align-middle fixedTd">
                      <ButtonGroup aria-label="First group" size="sm">
                        <AbatementProjectEdit
                          sites={sites}
                          project={project}
                          onAbatementProjectEdited={
                            handleOnAbatementProjectEdited
                          }
                          organization={organization}
                          orgEmissions={orgEmissions}
                          orgGroups={orgGroups}
                          orgCustomFields={orgCustomFields}
                          orgSiteLocations={orgSiteLocations}
                          isForecastingUpdate={isForecastingUpdate}
                          setIsForecastingUpdate={setIsForecastingUpdate}
                        />
                        <AbatementProjectDelete
                          project={project}
                          onAbatementProjectDeleted={
                            handleOnAbatementProjectDeleted
                          }
                        />
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <tr>
                <td className="text-nowrap px-3">Total</td>
                <td colSpan={13} className="text-end text-nowrap px-3">
                  <big>
                    {Intl.NumberFormat("en-us", {
                      style: "currency",
                      currency: "USD",
                    }).format(
                      projects
                        .filter((project) => {
                          if (!group.length) return true;
                          return group.filter(
                            (val) =>
                              project?.group?.name?.toLowerCase() ===
                              val?.name?.toLowerCase()
                          ).length;
                        })
                        .filter((project) => {
                          if (!status.length) return true;
                          return status.filter(
                            (val) =>
                              parseInt(project?.projectStatus) ===
                              parseInt(val.key)
                          ).length;
                        })
                        .filter((project) => {
                          if (!site.length) return true;
                          return site.filter(
                            (val) => project?.site?.id === val.id
                          ).length;
                        })
                        ?.filter((project) => {
                          if (!scenario.length) return true;
                          return scenario.filter((val) =>
                            val.projects?.some(
                              (data) => project?.id === data?.id
                            )
                          ).length;
                        })
                        ?.filter((project) => {
                          if (!location.length) return true;
                          return location.filter(
                            (val) => project?.location === val.name
                          ).length;
                        })
                        .reduce((acc, curr) => {
                          return acc + curr?.initialInvestment;
                        }, 0)
                    )}
                  </big>{" "}
                </td>
                <td colSpan={4} className="text-end text-nowrap px-3">
                  <big>
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 3,
                    }).format(
                      projects
                        .filter((project) => {
                          if (!group.length) return true;
                          return group.filter(
                            (val) =>
                              project?.group?.name?.toLowerCase() ===
                              val?.name?.toLowerCase()
                          ).length;
                        })
                        .filter((project) => {
                          if (!status.length) return true;
                          return status.filter(
                            (val) =>
                              parseInt(project?.projectStatus) ===
                              parseInt(val.key)
                          ).length;
                        })
                        .filter((project) => {
                          if (!site.length) return true;
                          return site.filter(
                            (val) => project?.site?.id === val.id
                          ).length;
                        })
                        ?.filter((project) => {
                          if (!scenario.length) return true;
                          return scenario.filter((val) =>
                            val.projects?.some(
                              (data) => project?.id === data?.id
                            )
                          ).length;
                        })
                        ?.filter((project) => {
                          if (!location.length) return true;
                          return location.filter(
                            (val) => project?.location === val.name
                          ).length;
                        })
                        .reduce((acc, curr) => {
                          return acc + curr?.annualCarbonEmissionReduction;
                        }, 0)
                    )}
                  </big>{" "}
                </td>
                <td></td>
              </tr>
            </tfoot>
          </Table>
        </div>
      )}
      {isShow &&
        !error &&
        !isLoading &&
        projects
          .filter((project) => {
            if (!group.length) return true;
            return group.filter(
              (val) =>
                project?.group?.name?.toLowerCase() === val?.name?.toLowerCase()
            ).length;
          })
          .filter((project) => {
            if (!status.length) return true;
            return status.filter(
              (val) => parseInt(project?.projectStatus) === parseInt(val.key)
            ).length;
          })
          ?.filter((project) => {
            if (!scenario.length) return true;
            return scenario.filter((val) =>
              val.projects?.some((data) => project?.id === data?.id)
            ).length;
          })
          ?.filter((project) => {
            if (!location.length) return true;
            return location.filter((val) => project?.location === val.name)
              .length;
          })
          .map((project) => project).length === 0 && (
          <Alert variant="info">There is currently nothing to show here.</Alert>
        )}
      {isShow && (
        <div className="my-4 p-3 border">
          <ContentDetails contentId={"625daa9813bea"} view={"full"} />
        </div>
      )}
    </div>
  );
};

export default Abatement;
