import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { Alert, Button, Form, Modal, Overlay, Popover } from "react-bootstrap";
import { put } from "utils/DeApi";
import * as yup from "yup";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import "./CustomGroupsEdit.scss";

const CustomGroupsEdit = ({
  fetchCustomGroups,
  groupId,
  name,
  filteredColors,
  color,
  group,
}) => {
  const [show, setShow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState();
  const [onSuccess, setOnSuccess] = useState(false);

  const [showPopover, setShowPopover] = useState(false);
  const targetRef = useRef(null);
  const subscribedPromises = useRef([]);

  const colors = [
    "#0A2B14",
    "#019A20",
    "#446D5D",
    "#82A78D",
    "#5BBE00",
    "#00ffbc",
  ];

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(2, "Too Short!")
      .max(100, "Too Long!")
      .required("Name is a required field"),
    color: yup
      .string()
      .matches(
        /^(#[0-9A-Fa-f]{6}|[A-Za-z]+)$/i,
        "Invalid color format. Use hex code or color name."
      )
      .min(2, "Too Short!")
      .max(20, "Too Long!")

      .required("Color is a required field"),
  });

  const handleClose = () => {
    setShow(false);
    setShowPopover(false);
    setOnSuccess(false);
    setError(null);
  };
  const handleShow = () => setShow(true);

  const editCustomGroup = ({ name, color }) => {
    setError(null);
    setIsLoading(true);

    const customGroupPromise = put(`opportunity-groups/${groupId}`, {
      name: name,
      color: color,
    });

    customGroupPromise.promise
      .then((response) => {
        setError(null);
        setOnSuccess(true);
        setIsLoading(true);
        fetchCustomGroups();
        handleClose();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(customGroupPromise);
  };

  return (
    <>
      <Button
        variant="outline-primary"
        size="sm"
        className={`py-0 ${
          (group?.project_count || !!group?.decarb_action_category?.length) &&
          "editBtnProjectCount"
        }`}
        onClick={handleShow}
      >
        <span className="material-icons-outlined md-16">tune</span>
      </Button>

      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Update Opportunity Grouping <i>{name}</i>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => editCustomGroup(values)}
          initialValues={{
            name: name,
            color: color || "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            isValid,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Group controlId="groupNName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter opportunity grouping name"
                    isValid={values.name}
                    isInvalid={errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="my-2" controlId="formColorInput">
                  <Form.Label>Choose Color</Form.Label>

                  <Form.Control
                    type="text"
                    name="color"
                    value={values.color}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Please select color or enter your custom code"
                    ref={targetRef}
                    isValid={values.color}
                    isInvalid={errors.color && touched.color}
                    onClick={() => setShowPopover(!showPopover)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.color}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button
                  size="sm"
                  className="mt-2 border-white"
                  style={{
                    backgroundColor: values.color ? values.color : "",
                  }}
                  onClick={() => setFieldValue("color", "")}
                >
                  <span className="material-icons-outlined fs-4 me-1">
                    restart_alt
                  </span>
                  Reset Color
                </Button>
                <Overlay
                  show={showPopover}
                  target={targetRef.current}
                  placement="bottom"
                  containerPadding={20}
                >
                  <Popover id="color-popover" style={{ width: "230px" }}>
                    <div
                      style={{
                        display: "flex",
                        padding: "10px",
                      }}
                    >
                      {colors
                        .filter((color) => !filteredColors?.includes(color))
                        .map((color) => (
                          <div
                            key={color}
                            className="mx-1"
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "5px",
                              backgroundColor: color,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setFieldValue("color", color);
                              setShowPopover(false);
                            }}
                            role="button"
                            tabIndex={0}
                            onKeyPress={(e) => {
                              // Handle keyboard events if needed
                              if (e.key === "Enter" || e.key === " ") {
                                setFieldValue("color", color);
                                setShowPopover(false);
                              }
                            }}
                          />
                        ))}
                      {colors
                        .map((item) => filteredColors.includes(item))
                        .every(Boolean) && (
                        <p>
                          <small>Default colors are in use.</small>
                        </p>
                      )}
                    </div>
                  </Popover>
                </Overlay>
                {isLoading && <Loader />}
                {error && (
                  <ErrorHandler
                    message={error?.data?.message}
                    showMsgOnly={true}
                  />
                )}{" "}
                {onSuccess && (
                  <Alert className="mt-2" variant="info">
                    Opportunity Grouping Updated Successfully
                  </Alert>
                )}
              </Modal.Body>

              <Modal.Footer>
                <Button size="sm" variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  disabled={!values.name || !values.color || isLoading}
                >
                  Update Opportunity Grouping
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default CustomGroupsEdit;
