import React, { useEffect } from "react";
import "./AbatementChart.scss";
import * as echarts from "echarts";

const AbatementChart = ({
  data = [],
  onChartRendered,
  min,
  max,
  setMin,
  setMax,
  showLabels,
  el,
}) => {
  useEffect(() => {
    const colorPalette = [
      "#5470c6",
      "#91cc75",
      "#fac858",
      "#ee6666",
      "#73c0de",
      "#3ba272",
      "#5470c6",
      "#fc8452",
      "#ea7ccc",

      "#00292A",
      "#331000",
      "#1C1429",
      "#1C1429",
      "#000F0B",
      "#007C7F",
      "#993000",
      "#563C7B",
      "#997800",
      "#007552",
      "#00A6A9",
      "#CC4000",
      "#7350A4",
      "#CCA000",
      "#008F64",
      "#00D0D4",
      "#FF5100",
      "#9064CD",
      "#FFC800",
      "#00C389",
    ];

    let sum = 0;

    const _data = data
      .filter(({ marginalAbatementCost }) => !!marginalAbatementCost)
      .slice(0, 50)
      .sort(({ marginalAbatementCost: a }, { marginalAbatementCost: b }) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      })
      .map(
        (
          { annualCarbonEmissionReduction, name, marginalAbatementCost, group },
          index
        ) => {
          return [
            annualCarbonEmissionReduction,
            marginalAbatementCost,
            name,
            sum,
            (sum = sum + annualCarbonEmissionReduction),
            group?.color || colorPalette[index % colorPalette.length],
          ];
        }
      );

    const maxDataLength = _data.length > 40;

    const option = {
      title: {
        show: _data.length === 0,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      },
      grid: {
        top: maxDataLength ? "5%" : "10%",
        bottom: maxDataLength ? "350" : "110",
        right: maxDataLength ? "0%" : "10%",
        left: maxDataLength ? "60" : "90",
      },
      tooltip: {},
      xAxis: {
        name: `Annual Emissions Reduction, tCO{subscript|2}e per year`,
        nameLocation: "center",
        nameGap: maxDataLength ? "30" : "90",
        splitLine: false,
        axisLabel: {
          formatter: function (value) {
            return Intl.NumberFormat("en-US", { notation: "compact" }).format(
              value
            );
          },
          lineHeight: maxDataLength ? 10 : 130,
        },
        nameTextStyle: {
          rich: {
            subscript: {
              verticalAlign: "bottom",
              padding: [8, 0, 0, 0],
              fontSize: 10,
            },
          },
        },
      },
      yAxis: {
        name: "Abatement Cost,  $ per tCO{subscript|2}e",
        nameLocation: "middle",
        nameRotate: "90",
        nameGap: maxDataLength ? "30" : "50",
        splitLine: false,
        axisLabel: {
          formatter: function (value) {
            return Intl.NumberFormat("en-US", { notation: "compact" }).format(
              value
            );
          },
        },
        nameTextStyle: {
          rich: {
            subscript: {
              verticalAlign: "bottom",
              padding: [8, 0, 0, 0],
              fontSize: 10,
            },
          },
        },
      },

      series: [
        {
          type: "custom",
          renderItem: function (params, api) {
            var yValue = api.value(1);
            var start =
              yValue < 0
                ? api.coord([api.value(3), 0])
                : api.coord([api.value(3), yValue]);
            var size = api.size([api.value(0), yValue]);
            return {
              type: "rect",
              shape: {
                x: start[0],
                y: start[1],
                width: size[0],
                height: size[1],
              },
              style: {
                ...api.style(),
                fill: api.value(5) || "#91cc75",
                textFill: "#000",
                fontSize: maxDataLength ? 9 : 10,
              },
            };
          },

          label: {
            show: showLabels,
            position: "bottom",
            formatter: "{b0}",
          },
          labelLayout: function (params) {
            if (maxDataLength) {
              return {
                x:
                  params.rect.x < 300
                    ? params.rect.x + params.rect.width + 120
                    : params.rect.x - params.rect.width - 200,
                y:
                  params.rect.x < 300
                    ? params.rect.y + params.rect.height + 200
                    : params.rect.y - 550,
                align: params.rect.x < 300 ? "bottom" : "top",
                moveOverlap: "shiftY",
              };
            } else {
              return {
                x:
                  params.rect.x < 300
                    ? params.rect.x + params.rect.width + 50
                    : params.rect.x - params.rect.width - 5,
                y:
                  params.rect.x < 300
                    ? params.rect.y + params.rect.height + 15
                    : params.rect.y - 120,
                align: params.rect.x < 300 ? "bottom" : "top",
                moveOverlap: "shiftY",
              };
            }
          },

          labelLine: {
            show: showLabels,
            length2: 12,
            lineStyle: { color: "#333" },
          },
          dimensions: ["potential", "cost", "abatement", "from", "to"],
          encode: {
            x: [4],
            y: 1,
            tooltip: [1],
            itemName: 2,
          },
          tooltip: {
            formatter: function (params) {
              return `${params.name}<br />
                      Abatement Cost: ${
                        params.data.at(1)
                          ? new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(params.data.at(1))
                          : "-"
                      } per tCO<sub>2</sub>e`;
            },
          },
          data: _data,
        },
      ],
    };

    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: maxDataLength ? 1000 : el.current.offsetWidth / 2,
    });

    if (!max || !Number.isFinite(max) || Number.isNaN(max)) {
      setMax(
        Math.max(
          ...[].concat(
            ...(option.series[0]?.data || []).map((val) => val[1] || 0)
          )
        )
      );
    }
    if (!min || !Number.isFinite(min) || Number.isNaN(min)) {
      setMin(
        Math.min(
          ...[].concat(
            ...(option.series[0]?.data || []).map((val) => val[1] || 0)
          )
        )
      );
    }
    option.yAxis.min = min > 0 ? 0 : min;
    option.yAxis.max = max < 0 ? 0 : max;
    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: el.current.offsetWidth / 2,
      });
    }

    window.addEventListener("resize", handleResize);

    onChartRendered &&
      chart.on("finished", () => {
        let src = chart.getDataURL({
          pixelRatio: 2,
          backgroundColor: "#fff",
        });
        onChartRendered(src);
      });

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, data]);

  return (
    <div className="AbatementChart">
      <div className="w-100" ref={el} />
    </div>
  );
};

export default AbatementChart;
