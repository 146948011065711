import PropTypes from "prop-types";
import { useRef, useState } from "react";

import { Formik } from "formik";
import {
  Alert,
  Badge,
  Button,
  Form,
  Modal,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import * as yup from "yup";

import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import Loader from "components/Loader/Loader";
import { get, upload, post } from "utils/DeApi";
import { replaceLongCharacter } from "utils/StringUtils";

import "./OrgActivitiesUpload.scss";

const OrgActivitiesUpload = ({ onActivitiesUploaded, organization }) => {
  const subscribedPromises = useRef([]);
  const attachmentRef = useRef();

  const [show, setShow] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const [step, setStep] = useState("step-1");
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleClose = () => {
    setIsLoading();
    setError();
    setSuccess();
    attachmentRef.current.reset();
    setShow(false);
    setStep("step-1");
    openModal(false);
    setModalData(false);
  };

  const openConfirmationModal = (data) => {
    setOpenModal(true);
    setModalData(data);
  };

  const handleShow = () => setShow(true);

  const submitFileId = (data) => {
    let fileSubmit = post(
      `/organizations/${organization.id}/activities/import/ml`,
      {
        import_id: data.id,
      }
    );
    fileSubmit.promise
      .then((response) => {
        setOpenModal(false);
        setShow(false);
        setError();
        setIsLoading(false);
        setSuccess(true);
        attachmentRef.current.reset();
        onActivitiesUploaded();
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
        setSuccess();
        setOpenModal(false);
      });
  };

  const uploadActvities = ({ file }) => {
    setError();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    const filePromise = upload(
      `organizations/${organization.id}/activities/import`,
      formData
    );

    filePromise.promise
      .then((response) => {
        setError();
        setIsLoading(false);
        setSuccess(true);
        attachmentRef.current.reset();
        onActivitiesUploaded();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
          setSuccess();
          setOpenModal(false);
        }
      });
    subscribedPromises.current.push(filePromise);
  };

  const uploadMLActivities = ({ file }) => {
    setError();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    const filePromise = upload(
      `organizations/${organization.id}/activities/import/for-ml`,
      formData
    );

    filePromise.promise
      .then((response) => {
        setError();
        setIsLoading(false);
        setSuccess(true);
        attachmentRef.current.reset();
      })
      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
          setSuccess();
        }
      });
  };

  const viewFiles = async () => {
    setIsLoading(true);
    setError(null);
    setSuccess(false);
    attachmentRef.current.reset();
    const api = `organizations/${organization.id}/activities/import/for-ml`;
    try {
      const { data } = await get(api).promise; // Access the promise property and await it
      setHistoryData(data);
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const schema = yup.object().shape({
    file: yup
      .mixed()
      .required("A file is required")
      .test(
        "fileFormat",
        "The file should be in CSV or XLSX format (.csv or .xlsx extension only)",
        (value) => {
          if (!value) return false; // If no file is selected, fail the test
          const fileType = value.type; // Get the MIME type of the selected file
          return (
            fileType === "text/csv" ||
            fileType ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        }
      ),
  });

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        size="sm"
        id="import-activities-button"
      >
        Import Activities <Badge bg="danger">BETA</Badge>
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Import Activities <Badge bg="danger">BETA</Badge>
          </Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            if (step === "step-1") {
              uploadActvities(values);
            } else {
              uploadMLActivities(values);
            }
          }}
          initialValues={{ file: "", type: "" }}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            setFieldValue,
            handleChange,
            touched,
            errors,
            isValid,
            resetForm,
          }) => (
            <Form onSubmit={handleSubmit} ref={attachmentRef}>
              <Modal.Body>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={step}
                  onSelect={(k) => {
                    setStep(k);
                    resetForm();
                    if (k === "step-3") {
                      viewFiles();
                    }
                    setFieldValue();
                    setError();
                    setSuccess();
                    attachmentRef.current.reset();
                  }}
                  className="mb-3"
                >
                  <Tab
                    eventKey="step-1"
                    title={
                      <>
                        Upload File{" "}
                        {(!values.year ||
                          (errors.year && touched.year) ||
                          !values.endYear ||
                          (errors.endYear && touched.endYear) ||
                          !values.emissionsScopeOne ||
                          (errors.emissionsScopeOne &&
                            touched.emissionsScopeOne) ||
                          !values.emissionsScopeTwo ||
                          (errors.emissionsScopeTwo &&
                            touched.emissionsScopeTwo)) && (
                          <sup className="ps-1 fs-3 top-0 text-danger">*</sup>
                        )}
                      </>
                    }
                  >
                    <ContentDetails contentId={"643ee76dd1f82"} />

                    <Form.Group controlId="file" className="mb-3 mt-4">
                      <Form.Label>Please attach csv file.</Form.Label>
                      <Form.Control
                        type="file"
                        name="file"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          setFieldValue("file", file);
                        }}
                        onBlur={handleBlur}
                        isValid={touched.file && !errors.file}
                        accept=".csv"
                        multiple
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.file}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Tab>
                  <Tab eventKey="step-2" title="Validate File">
                    <ContentDetails contentId={"66212d7ea4041"} />

                    <Form.Group controlId="file" className="mb-3 mt-4">
                      <Form.Label>Please attach csv or xlsx file.</Form.Label>
                      <Form.Control
                        type="file"
                        name="file"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          setFieldValue("file", file);
                        }}
                        onBlur={handleBlur}
                        isValid={touched.file && !errors.file}
                        accept=".csv, .xlsx"
                        multiple
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.file}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Tab>
                  <Tab
                    className="tableClass"
                    eventKey="step-3"
                    title={<>File History</>}
                  >
                    <Table hover className="first-column-fixed" responsive>
                      <thead>
                        <tr>
                          <th className="table-sorter ps-3 text-nowrap">
                            File Name
                          </th>
                          <th className="table-sorter ps-3 text-nowrap">
                            Status
                          </th>
                          <th className="table-sorter ps-3 text-nowrap">
                            Remarks
                          </th>
                          <th className="table-sorter ps-3 text-nowrap">
                            Created At
                          </th>
                          <th className="table-sorter ps-3 text-nowrap"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {historyData.map((data) => (
                          <tr key={data.id}>
                            <td className="text-truncate">
                              <a
                                href={data.file}
                                target="_blank"
                                rel="noreferrer"
                                className="text-decoration-none"
                                download={true}
                              >
                                {replaceLongCharacter(data.filename)}
                              </a>
                            </td>
                            <td className="ps-3 text-nowrap text-capitalize">
                              {data.status.replace(/_/g, " ")}
                            </td>
                            <td className="ps-3 text-nowrap text-capitalize">
                              {100 -
                                data?.ml_process_response?.percentage_of_rows ||
                                0}
                              %
                            </td>
                            <td className="ps-3 text-nowrap">
                              <small>
                                {new Date(data.updatedAt).toLocaleString([], {
                                  dateStyle: "short",
                                  timeStyle: "short",
                                })}
                              </small>
                            </td>
                            <td className="ps-3 text-nowrap pl-3">
                              <Button
                                disabled={
                                  data.status !== "completed" ||
                                  data.ml_process_response === null ||
                                  data.activity_import_status === 1 ||
                                  data?.ml_process_response
                                    ?.percentage_of_rows !== "0.00"
                                }
                                onClick={() => openConfirmationModal(data)}
                              >
                                {data.activity_import_status === 1
                                  ? "Uploaded"
                                  : "Use file"}
                              </Button>
                              <span className="material-icons-outlined md-18 table-sorter">
                                <a
                                  className="text-decoration-none mx-2"
                                  href={data.ml_processed_file}
                                >
                                  {" "}
                                  file_download{" "}
                                </a>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Tab>
                </Tabs>
                {isLoading && <Loader />}
                {error && (
                  <Form.Text className="text-danger">
                    {error.data.message}
                  </Form.Text>
                )}
                {success && (
                  <Alert variant="info">
                    Activities uploaded successfully!
                  </Alert>
                )}
              </Modal.Body>
              {step !== "step-3" && (
                <Modal.Footer>
                  <Button size="sm" variant="link" onClick={handleClose}>
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    size="sm"
                    disabled={!isValid || isLoading || !attachmentRef.current}
                  >
                    Import
                  </Button>
                </Modal.Footer>
              )}
            </Form>
          )}
        </Formik>
      </Modal>

      {openModal && (
        <Modal show={openModal} onHide={!openModal} centered>
          <Modal.Body>
            <p className="m-0">
              <b>Confirmation Message</b>
            </p>

            <Form.Group controlId="name" className="mt-3 mb-3">
              <Form.Label>Are you sure you want to use this file? </Form.Label>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              No
            </Button>
            <Button
              onClick={() => submitFileId(modalData)}
              size="sm"
              variant="danger"
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default OrgActivitiesUpload;
