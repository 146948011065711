import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Table, Alert } from "react-bootstrap";

import BreadCrumbs from "components/App/BreadCrumbs/BreadCrumbs";
import ContentDetails from "components/Content/ContentDetails/ContentDetails";
import { get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { useParams } from "react-router-dom";
import ManageS2Reductions from "./ManageS2Reductions/ManageS2Reductions";
import GridDecarbonizationChart from "./GridDecarbonizationChart/GridDecarbonizationChart";
import "./GridDecarbonization.scss";
import { formatNumber, formatPercentage } from "../helper";
import {
  getEconomicGrowth,
  getGridDecarbonization,
} from "../forecastingModels";

import UserCategories from "utils/userCategories";
import DataLayer from "utils/DataLayer";
import { TableSortArrows } from "components/Shared/TableComponents";

const GridDecarbonization = ({
  organization,
  breadcrumbs,
  baselineYear,
  scopeOne,
  scopeTwo,
  scopeThree,
  isForecastingUpdate,
  setIsForecastingUpdate,
  orgEconomicGrowth,
  orgEconomicGrowthEmissionPercentage,
  orgGridDecarbonization,
  orgGridDecarbonizationEmissionPercentage,
  orgGridDecarbonizationEmissionPercentageThree,
}) => {
  const [emission, setEmission] = useState([]);
  const [growthEmission, setGrowthEmission] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [scopeTwoReduction, setScopeTwoReduction] = useState(100);

  const [scopeThreeReduction, setScopeThreeReduction] = useState(100);
  const [scopeThreeReductionGrowth, setScopeThreeReductionGrowth] =
    useState(100);
  const subscribedPromises = useRef([]);
  const { organizationId } = useParams();

  const userCategory = UserCategories();

  const [gridDecarbonizationData, setGridDecarbonizationData] = useState([]);

  const [tableSortState, setTableSortState] = useState({
    sortColumn: "year",
    updatedAt: true,
    sortAsc: false,
  });

  const currentPath = window.location.pathname.split("/").pop();

  const sortingTable = (column, objValue) => {
    emission?.data?.sort((a, b) => {
      let macA = objValue ? a[column]?.[objValue] : a[column];
      let macB = objValue ? b[column]?.[objValue] : b[column];
      if (macA == null) macA = 0; // Convert null to 0
      if (macB == null) macB = 0; // Convert null to 0

      if (macA === "" || macA === "n/a") {
        return tableSortState[column] ? 1 : -1;
      }

      if (macB === "" || macB === "n/a") {
        return tableSortState[column] ? -1 : 1;
      }

      if (typeof macA === "number" && typeof macB === "number") {
        if (!tableSortState[column]) return macB - macA;
        return macA - macB;
      } else {
        if (!tableSortState[column])
          return macA.localeCompare(macB, "en-us") <= 0 ? 1 : -1;
        else return macA.localeCompare(macB, "en-us") >= 0 ? 1 : -1;
      }
    });

    tableSortState.sortColumn = column;
    tableSortState.sortAsc = tableSortState[column] ? true : false;
    tableSortState[column] = !tableSortState[column];

    setTableSortState({ ...tableSortState });
    setEmission([...emission]);
  };

  useEffect(() => {
    const fetchEmission = (organizationId) => {
      setError(null);
      setIsLoading(true);
      const economicGrowthPromise = get(
        `organizations/${organizationId}/economic-growth`
      );
      const gridDecarbonizationPromise = get(
        `organizations/${organizationId}/grid-decarbonization`
      );
      Promise.all([
        economicGrowthPromise.promise,
        gridDecarbonizationPromise.promise,
      ])
        .then((responses) => {
          setIsLoading(false);

          const [{ data: economicGrowth = [] }, { data: gridDecarb = [] }] =
            responses || [];

          setScopeTwoReduction(gridDecarb[0]?.scopeTwoEmissionPercentage || 0);

          setScopeThreeReduction(
            gridDecarb[0]?.scopeThreeEmissionPercentage || 0
          );

          setEmission({
            ...gridDecarb[0],
          });

          setScopeThreeReductionGrowth(
            economicGrowth[0]?.scopeThreeEmissionPercentage || 0
          );

          calculateDecarb(
            { ...gridDecarb[0] },
            economicGrowth[0] ? economicGrowth[0]?.data : [],
            gridDecarb[0] ? gridDecarb[0]?.data : [],
            gridDecarb[0]?.scopeTwoEmissionPercentage || 0,
            gridDecarb[0]?.scopeThreeEmissionPercentage || 0,
            economicGrowth[0]?.scopeThreeEmissionPercentage || 0
          );
          setGridDecarbonizationData(gridDecarb[0]?.calculation);
        })
        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(
        gridDecarbonizationPromise,
        economicGrowthPromise
      );
    };

    // if (isForecastingUpdate && currentPath === isForecastingUpdate?.path) {
    fetchEmission(organizationId);
    // } else {
    //   setIsForecastingUpdate();
    //   setScopeTwoReduction(
    //     !orgGridDecarbonizationEmissionPercentage ||
    //       !orgGridDecarbonizationEmissionPercentage?.length
    //       ? 0
    //       : orgGridDecarbonizationEmissionPercentage
    //   );
    //   setScopeThreeReduction(
    //     !orgGridDecarbonizationEmissionPercentageThree ||
    //       !orgGridDecarbonizationEmissionPercentageThree?.length
    //       ? 0
    //       : orgGridDecarbonizationEmissionPercentageThree
    //   );
    //   setEmission({
    //     ...orgGridDecarbonization,
    //   });
    //   setScopeThreeReductionGrowth(
    //     !orgEconomicGrowthEmissionPercentage ||
    //       !orgEconomicGrowthEmissionPercentage?.length
    //       ? 0
    //       : orgEconomicGrowthEmissionPercentage
    //   );
    //   calculateDecarb(
    //     !!orgGridDecarbonization?.length
    //       ? { ...orgGridDecarbonization[0] }
    //       : { ...orgGridDecarbonization },
    //     orgEconomicGrowth || [],
    //     orgGridDecarbonization ? orgGridDecarbonization?.data : [],
    //     !orgGridDecarbonizationEmissionPercentage ||
    //       !orgGridDecarbonizationEmissionPercentage?.length
    //       ? 0
    //       : orgGridDecarbonizationEmissionPercentage,
    //     !orgGridDecarbonizationEmissionPercentageThree ||
    //       !orgGridDecarbonizationEmissionPercentageThree?.length
    //       ? 0
    //       : orgGridDecarbonizationEmissionPercentageThree,
    //     !orgEconomicGrowthEmissionPercentage ||
    //       !orgEconomicGrowthEmissionPercentage?.length
    //       ? 0
    //       : orgEconomicGrowthEmissionPercentage
    //   );
    //   setGridDecarbonizationData(orgGridDecarbonization?.calculation);
    // }

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [
    // organizationId,
    // isForecastingUpdate,
    organizationId,
    scopeTwo,
    emission?.scopeTwoEmissionPercentage,
    emission?.scopeThreeEmissionPercentage,
    isForecastingUpdate,
  ]);

  const calculateDecarb = (
    emissions = {},
    economicGrowthData = [],
    gridDecarbData = [],
    scopeTwoPercentage = 100,
    scopeThreePercentage = 100,
    scopeThreePercentageGrowth = 100
  ) => {
    const economicGrowthChunk = getEconomicGrowth(
      economicGrowthData,
      scopeOne,
      scopeTwo,
      scopeThree,
      scopeThreePercentageGrowth
    );

    const gridDecarbChunk = getGridDecarbonization(
      gridDecarbData,
      economicGrowthChunk,
      scopeTwoPercentage,
      scopeThreePercentage
    );

    setGrowthEmission(economicGrowthChunk);

    setEmission({
      ...emissions,
      data: gridDecarbChunk,
    });
  };

  return (
    <div id={userCategory}>
      <DataLayer />
      <div className="my-3">
        <BreadCrumbs
          breadcrumbs={[
            ...breadcrumbs,
            {
              name: "Grid Decarbonization",
              link: `/organizations/${organization.id}/projections/3/grid-decarbonization`,
              active: true,
            },
          ]}
        />
      </div>
      <h2 className="mb-4 pt-2">Decarb of Grid Electricity</h2>
      <div className="my-3 p-3 border">
        <h3>
          Emissions Projections
          <ManageS2Reductions
            onManageS2ReductionsUpdated={(data) =>
              setEmission({
                ...data,
                data: getGridDecarbonization(
                  data.data,
                  growthEmission,
                  scopeTwoReduction,
                  scopeThreeReduction
                ),
              })
            }
            emissions={emission}
            baselineYear={baselineYear}
            isForecastingUpdate={isForecastingUpdate}
          />
        </h3>
        {!isLoading && !error && (
          <GridDecarbonizationChart
            data={gridDecarbonizationData}
            scopeTwo={scopeTwo}
          />
        )}
        {error && !isLoading && <ErrorHandler error={error} />}
        {isLoading && <Loader />}
      </div>
      <div className="GridDecarbonizationDiv">
        <Table size="sm" striped responsive className="first-column-fixed">
          <thead>
            <tr>
              <th
                className="text-end text-nowrap px-3 table-sorter year-column"
                onClick={() => sortingTable("year")}
              >
                Year{" "}
                <TableSortArrows
                  sortColumn={tableSortState.sortColumn}
                  colName={"year"}
                  sortAsc={tableSortState.sortAsc}
                />
              </th>
              <th
                className="text-end text-nowrap px-3 table-sorter"
                onClick={() => sortingTable("cumulativeReduction")}
              >
                % Reduction in Grid Electricity Factor
                <TableSortArrows
                  sortColumn={tableSortState.sortColumn}
                  colName={"cumulativeReduction"}
                  sortAsc={tableSortState.sortAsc}
                />
              </th>
              <th
                className="text-end text-nowrap px-3 table-sorter"
                onClick={() => sortingTable("s2_reduction_percent")}
              >
                Cumulative % Reduction
                <TableSortArrows
                  sortColumn={tableSortState.sortColumn}
                  colName={"s2_reduction_percent"}
                  sortAsc={tableSortState.sortAsc}
                />
              </th>
              <th
                className="text-end text-nowrap px-3 table-sorter"
                onClick={() => sortingTable("s2_reduction")}
              >
                Scope 2 Reduction{" "}
                <small>
                  (tCO<sub>2</sub>e)
                </small>
                <TableSortArrows
                  sortColumn={tableSortState.sortColumn}
                  colName={"s2_reduction"}
                  sortAsc={tableSortState.sortAsc}
                />
              </th>
              <th
                className="text-end text-nowrap px-3 table-sorter"
                onClick={() => sortingTable("s2_emission")}
              >
                Scope 2 Emissions
                <small>
                  (tCO<sub>2</sub>e)
                </small>
                <TableSortArrows
                  sortColumn={tableSortState.sortColumn}
                  colName={"s2_emission"}
                  sortAsc={tableSortState.sortAsc}
                />
              </th>
              <th
                className="text-end text-nowrap px-3 table-sorter"
                onClick={() => sortingTable("s3_reduction")}
              >
                Scope 3 Reduction{" "}
                <small>
                  (tCO<sub>2</sub>e)
                </small>
                <TableSortArrows
                  sortColumn={tableSortState.sortColumn}
                  colName={"s3_reduction"}
                  sortAsc={tableSortState.sortAsc}
                />
              </th>
              <th
                className="text-end text-nowrap px-3 table-sorter"
                onClick={() => sortingTable("s3_emission")}
              >
                Scope 3 Emissions
                <small>
                  (tCO<sub>2</sub>e)
                </small>
                <TableSortArrows
                  sortColumn={tableSortState.sortColumn}
                  colName={"s3_emission"}
                  sortAsc={tableSortState.sortAsc}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {emission?.data?.map(
              (
                {
                  year,
                  cumulativeReduction,
                  s2_reduction_percent,
                  s2_reduction,
                  s2_emission,
                  s3_reduction,
                  s3_emission,
                  emission,
                },
                index
              ) => (
                <tr key={index}>
                  <td className="text-end text-nowrap px-3 year-column">
                    {year}
                  </td>
                  <td className="text-end text-nowrap px-3">
                    {formatPercentage(s2_reduction_percent)}
                  </td>
                  <td className="text-end text-nowrap px-3">
                    {formatPercentage(cumulativeReduction)}
                  </td>
                  <td className="text-end text-nowrap px-3">
                    {formatNumber(s2_reduction, 2)}
                  </td>
                  <td className="text-end text-nowrap px-3">
                    {formatNumber(s2_emission, 2)}
                  </td>
                  <td className="text-end text-nowrap px-3">
                    {formatNumber(s3_reduction, 2)}
                  </td>
                  <td className="text-end text-nowrap px-3">
                    {formatNumber(s3_emission, 2)}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </div>

      {!error && !isLoading && !emission?.id && (
        <Alert variant="info">There is currently nothing to show here.</Alert>
      )}
      <div className="my-4 p-3 border">
        <ContentDetails contentId={"62e185b35099e"} view={"full"} />
      </div>
    </div>
  );
};

GridDecarbonization.propTypes = {
  organization: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  baselineYear: PropTypes.number,
  scopeTwo: PropTypes.number,
};

export default GridDecarbonization;
